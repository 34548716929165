import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="content-body-footer">
        <p className="footer-description">2024 Copyright | Deloitte</p>
      </div>
    </footer>
  );
};

export default Footer;
