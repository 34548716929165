import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import Home from "./Home/Home";
import UserManagement from "./UserManagement/UserManagement";
import NotificationProvider from "./Notification/NotificationToast";
import LoaderProvider from "./Loader/LoaderProvider";
import BodyContent from "./BodyContent/BodyContent";
import Footer from "./Footer/Footer";
import Cookies from "js-cookie";
//

import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

function App({ pca }) {
  let isAdmin = Cookies.get("isAdmin") === "true";
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);
  // const [isAdmin, setIsAdmin] = useState(false);

  // const [isAdminOrApproved, setIsAdminOrApproved] = useState(false);

  // //call UserType
  // const params = {
  //   logged_in_user: "haritiwari@deloitte.com",
  // };

  // useEffect(() => {
  //   axios
  //     .get("http://127.0.0.1:5000/user_status", { params })
  //     .then(function (response) {
  //       setIsAdmin(response.data[0].is_admin);
  //       setIsAdminOrApproved(response.data[0].admin_or_authenticated);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, [params]);

  return (
    <MsalProvider instance={pca}>
      <div className="App">
        <div className="content">
          <NotificationProvider>
            <LoaderProvider>
              <AuthenticatedTemplate>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/capabilities" element={<BodyContent />} />
                  {isAdmin === true && (
                    <Route
                      path="/user-management"
                      element={<UserManagement />}
                    />
                  )}
                </Routes>
                <Footer />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Routes></Routes>
                <Footer />
              </UnauthenticatedTemplate>
            </LoaderProvider>
          </NotificationProvider>
        </div>
      </div>
    </MsalProvider>
  );
}

export default App;
